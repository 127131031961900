@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #efd8c6;
}

@layer components {
  /* p {
    line-height: 2;
  } */
  .svg-bg {
    background-image: url("./images/bg.svg");
  }
  .img-bg {
    background-image: url("./images/BG.png");
  }
  .img-bg {
    background-image: url("./images/BG2.png");
  }
  .text-webkit-center {
    text-align: -webkit-center;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .navbar-icon {
    @apply relative flex items-center justify-center h-12 w-12 mt-2 mb-2 mx-auto shadow-lg bg-white text-cyan-300 hover:text-black rounded-3xl hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer;
  }
  .navbar-tooltip {
    @apply absolute w-auto p-2 m-2 min-w-max left-14 rounded-md shadow-md text-white bg-gray-900 text-xs font-bold transition-all duration-100 scale-0 origin-left;
  }
  .content {
    @apply bg-primary h-[100%] w-[100%] flex justify-start items-center;
  }

  .about-text {
    @apply bg-[rgb(185,147,130,75%)];
  }

  .dot {
    @apply w-10 p-1;
  }
  .about-image {
    @apply w-full object-cover object-center;
  }
  .bio-image {
    @apply h-[100%] w-full object-cover object-center;
  }
  .about-section {
    @apply h-[35%] flex flex-col content-center items-center  w-[100%] pt-4;
  }
  .dots {
    @apply h-[10%] flex flex-row justify-center items-center;
  }
  .hundo {
    @apply h-[100%] w-[100%];
  }
  .form-input {
    @apply m-1 w-full backdrop-blur-sm bg-clear/40 shadow-sm placeholder-slate-400
    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
    disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
    invalid:border-pink-500 invalid:text-pink-600
    focus:invalid:border-pink-500 focus:invalid:ring-pink-500 border border-slate-300 rounded-md;
  }
  .body-text-sizing {
    @apply sm:text-xs md:text-md lg:text-sm xl:text-base;
  }
  .bgClear {
    @apply backdrop-blur-md bg-clear/30;
  }
}
